body {
  text-decoration: none;
  color: #232323;
}

.color-nav {
  color: #30408c;
}

.AppLogo {
  width: 50vmin;
  height: 13gmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .AppLogo {
    animation: App-logo-spin infinite 200s linear;
  }
}

.nav-custom-item{
  color: #30408c;
}

@media screen and (min-width: 720px) {
  .logoimg {
    width: 300px;
  }
  .phonepic{
    font-size: 34px;
  }
  .imgpic{
    width: 100%;
  }
}

@media screen and (max-width: 720px) {
  .logoimg {
    width: 150px;
  }
  .phonepic{
    font-size: 16px;
  }
  .imgpic{
    width:100%;
  }
}